import React, {useState, useEffect} from "react"
import { graphql } from 'gatsby';
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useInView } from "react-intersection-observer";
import useViewport from '../utils/useViewport'
import { useData } from "../utils/DataProvider";
import RichText from '../utils/RichText';
import LayoutInner from "../components/LayoutInner"
import Seo from "../components/Seo"
import Section from "../components/Section";
import ScrollSpy from "../utils/ScrollSpy";
import Cover from "../components/Cover";

gsap.registerPlugin(ScrollTrigger);

const IndexPage = ({data, location}) => {
  const [active, setActive] = useState(null);
  const [viewCover, setViewCover] = useState(null);
  const [state, setState] = useData();
  const { ref, inView } = useInView({
    rootMargin: "-50px 0px",
    threshold: 0,
  });
  const {width} =  useViewport();
  const isMobile = width < 768;
  const homeData = data.homeQuery;
  const sections = homeData.edges[0].node.sections;
  const menu = sections.map(el => {
    return {
      name: el.anchorName ? el.anchorName : el.name,
      id: el.anchor,
    };
  });
  const sectionsIDs = menu.map(item => item.id);

  // animations

  const staggerSection = (el) => gsap.from(`${el} .jsStaggerText > *`, {
    duration: 1.5,
    opacity: 0,
    y: 20,
    stagger: 0.3,
    ease: "power4.inOut",
    scrollTrigger: {
      trigger: el,
      start: 'top 60%',
    }
  });

  const heroAnimation = () => {
    gsap.from('.jsHeader', { opacity: 0, duration: 0.5, delay: 1, x: 20 })
    gsap.from('.jsCover', {
      duration: 1,
      opacity:0,
      delay: .5,
      x: -40,
    })
    gsap.from('.jsPageContent', {
      duration: .5,
      opacity:0,
      delay: 1,
      x: 20,
    })
    staggerSection('.section1');
    gsap.from('.jsCite', { opacity: 0, duration: 1, delay: 2 })
    gsap.from('.jsLocalNav', { opacity: 0, duration: 1, delay: 2.5 })
    gsap.from('.jsFooter', { opacity: 0, duration: 1, delay: 3 })
  }

  const section1Animation = () => {
    staggerSection('.section2');
  }

  const section2Animation = () => {
    staggerSection('.section3');
  }
  const section3Animation = () => {
    staggerSection('.section4');
  }

  const section4Animation = () => {
    staggerSection('.section5');
  }

  const section5Animation = () => {
    staggerSection('.section6');
  }

  useEffect(() => {
    heroAnimation();
    section1Animation();
    section2Animation();
    section3Animation();
    section4Animation();
    section5Animation();
  }, [])

  // Hook to Make cover floatable on mobile
  useEffect(() => {
    setState({ ...state, isCoverFixed: !inView});
  }, [inView])

  return (
    <LayoutInner location={location}>
      <Seo title={homeData.title} />
      {!isMobile && (
        <ScrollSpy
            scrollTargetIds={sectionsIDs}
            activeNavClass="spyLinkActive"
            offset={-100}
          >
          <ul className="spyNav jsLocalNav">
            {menu.map((item, index) => (
              <li key={index}>
                <a
                  href={`#${item.id}`}
                  title={item.name}
                  className={`spyLink ${active === item.id ? "spyLinkActive" : ""}`}
                  onClick={() => setActive(item.id)}
                >
                  <span className="spyLinkText">{item.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </ScrollSpy>
      )}
      <div className="pageWrapper">
        <div className="pageCover jsCover" ref={ref}>
          <Cover/>
        </div>
        <div className="pageContent jsPageContent">
          {sections.map((section, index) => (
            <Section
              key={index}
              color={section.color}
              anchor={section.anchor}
              className={`inner section${index+1}`}>
              <div className="inner-wrap jsStaggerText">
                <h2>{section.name}</h2>
                {section?.content && <RichText data={section.content} />}
                {section.quotes && (
                  <div>
                    {section.quotes?.map((quote, index) => (
                      <div key={index} className="cite">
                        <RichText data={quote.content} />
                        <p>{quote.name}</p>
                        <span className="lite">{quote.nameTitle}</span>
                      </div>
                    ))}
                  </div>
                )}
                {section.stores && (
                  <ul className="stores">
                    {section.stores.map((store, index) => (
                      <li key={index}>
                        <a href={store.link} target='_blank' rel='noopener noreferrer' title={store.title}>
                          <img src={store.logo.file.url} alt={store.title} />
                        </a>
                      </li>
                    ))}
                  </ul>
                  )
                }
              </div>
            </Section>
          ))}
        </div>
        {(isMobile && state.isCoverFixed) &&
          <div
            className="mobileCover"
            data-expand={viewCover}
            onClick={() => setViewCover(!viewCover)}><Cover/></div>
        }
      </div>

    </LayoutInner>
  )
}

export default IndexPage

export const query = graphql`
  query Index {
    homeQuery: allContentfulPage(filter: { contentful_id: { eq: "4A0BgxDzuQlx2Zs3jcq6VN" } }) {
      edges {
        node {
          contentful_id
          keywords
          title
          sections {
            anchor
            anchorName
            color
            name
            content {
              raw
            }
            quotes {
              name
              nameTitle
              content {
                raw
              }
            }
            stores {
              logo {
                file {
                  url
                }
              }
              link
              title
            }
          }
        }
      }
    }
  }
`;

