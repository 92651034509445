import React, { useEffect } from "react";
import PropTypes from "prop-types";

const isBrowser = typeof window !== "undefined";

const ScrollSpy = ({
  scrollTargetIds,
  activeNavClass,
  offset,
  router,
  children,
}) => {
  // const homeDefaultLink = router && router === 'HashRouter' ? '#/' : '/';
  const hashIdentifier = router && router === 'HashRouter' ? '#/#' : '#';

  useEffect(() => {
    isBrowser && window.addEventListener("scroll", onScroll);
    return () => {
      isBrowser && window.removeEventListener("scroll", onScroll);
    };
  }, []) ;
  /**
   * Scroll event handler. It checks the current window offset and compares it with the pageYOffset of each
   *  target sections. It highlights the nav link when scrolling to a corresponding section
   */
  const onScroll = () => {
    let scrollSectionOffsetTop;
    let scrollSectionElement;
    scrollTargetIds.forEach((sectionID, index) => {
      scrollSectionElement = document.getElementById(sectionID);
      if (!scrollSectionElement) {
        console.warn(
          `No element with id ${sectionID} present in the DOM`
        );
        return;
      }

      scrollSectionOffsetTop = scrollSectionElement.offsetTop;

      if (
        isBrowser &&
        window.pageYOffset - offset >= scrollSectionOffsetTop &&
        window.pageYOffset <
          scrollSectionOffsetTop + scrollSectionElement.scrollHeight
      ) {
        getNavLinkElement(sectionID).classList.add(activeNavClass);
        clearOtherNavLinkActiveStyle(sectionID);
      } else {
        getNavLinkElement(sectionID).classList.remove(activeNavClass);
      }

      if (
        isBrowser &&
        window.innerHeight + window.pageYOffset >= document.body.scrollHeight &&
        index === scrollTargetIds.length - 1
      ) {
        getNavLinkElement(sectionID).classList.add(activeNavClass);
        clearOtherNavLinkActiveStyle(sectionID);
      }
    });
  };


  /**
   * Get the nav link element with a given sectionID that the nav link links to
   * @param {String} sectionID
   */
  const getNavLinkElement = (sectionID) => {
    return document.querySelector(
      `a[href='${hashIdentifier}${sectionID}']`
    );
  };

  /**
   * Clear the highlight style on the non-current viewed nav elements
   * @param {String} excludeSectionID
   */
  const clearOtherNavLinkActiveStyle = (excludeSectionID) => {
    scrollTargetIds.forEach(sectionID => {
      if (sectionID !== excludeSectionID) {
        getNavLinkElement(sectionID).classList.remove(activeNavClass);
      }
    });
  };



  return <div data-nav="list">{ children }</div>;
};

ScrollSpy.propTypes = {
  scrollTargetIds: PropTypes.array.isRequired,
  activeNavClass: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  offset: PropTypes.number,
  router: PropTypes.string,
};

ScrollSpy.defaultProps = {
  offset: 0
};

export default ScrollSpy;
